<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button
        to="/admin/configuraciones/autoridades-regulatorias/crear"
      >
        Crear autoridad regulatoria
      </c-primary-button>
    </div>
    <c-card title="Autoridades regulatorias">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Autoridad regulatoria</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="autoridad in autoridades" :key="autoridad.id">
            <td>{{ autoridad.name }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/configuraciones/autoridades-regulatorias/${autoridad.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteAutoridad(autoridad.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { all, remove } from "@/services/autoridades";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      autoridades: [],
    };
  },
  mounted() {
    this.getAutoridades();
  },
  methods: {
    getAutoridades() {
      all()
        .then((response) => {
          this.autoridades = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las autoridades regulatorias");
        });
    },
    deleteAutoridad(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getAutoridades();
      });
    },
  },
};
</script>
